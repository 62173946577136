@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Lato:wght@300;400&family=Yeseva+One&display=swap');

// @import "~slick-carousel/slick/slick.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.book-item {
  .ant-card-body {
    padding: 0;
  }

  height: 250px;
  border-radius: 0 !important;
  background-color: transparent !important;

  &.mobile {
    height: 150px;

    .book-item-image {
      max-height: 140px !important;
    }
  }

  &-image {
    max-height: 240px;
    width: auto;



    &:hover {
      cursor: pointer;
    }
  }
}

.book-type-select {
   .ant-segmented-item-selected {
     // border: 1px solid red;
     background-color: purple;
   }
}

.book-box {
  border-radius: 30px !important;
  border-width: 3px !important;
  border-style: solid;
  margin-top: 20px !important;

  .ant-card-head {
    border-bottom: none;
    .ant-card-head-title {
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;
      text-align: left;
    }
  }

  .ant-card-body {
    padding-top: 0;
  }

  &.book-box-single {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-form-item {
    text-align: left;
  }

  ul {
    margin-left: 15px;
  }
}

.book-box-1 {
  background-color: #f1c9f8 !important;
  border-color: #eba5f9 !important;

  .ant-card-body {
    padding-top: 20px;
  }
}

.book-box-2 {
  background-color: #d8ccfa !important;
  border-color: #b7a0f2 !important;
}

.book-box-3 {
  background-color: #d5baff !important;
  border-color: #c9a6fd !important;
}

.book-box-4 {
  background-color: #bbdcf3 !important;
  border-color: #8ab3d0 !important;
}

.book-box-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.book-box-right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.book-edit {
  float: right !important;
}

.book-field-title {
  span {
    font-weight: 700;
    text-transform: uppercase;
    font-style: italic;
  }
}


.book-details {
  .ant-descriptions-item-label {
    font-weight: 700;
    text-transform: uppercase;
    font-style: italic;
  }
}



.ant-layout-content {
  padding-top: 30px !important;
  background-image: url('../images/bg.jpg');
}

.ant-page-header {
  background-color: transparent !important;
}

.ant-rate-star-zero {
  .ant-rate-star-second {
    .anticon {
      color: #a6a6a6;
    }
  }
}

.book-type-icon {
  max-width: 70px;
}








.ant-select-focused {
  .ant-select-selection-search-input {
    margin-top: 15px !important;
  }
}

.help-button {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .anticon {
    font-size: 24px;
  }
}

.welcome-modal {
  .ant-modal-footer {
    text-align: center !important;
  }
}

.ant-card-cover {
  border-left: 1px solid rgb(240, 240, 240);
  border-top: 1px solid rgb(240, 240, 240);
  border-right: 1px solid rgb(240, 240, 240);
}

.mobile-menu-container {
   text-align: center !important;
   width: 100%;
   background-color: #fff;
   position: fixed;
   bottom: 0;
   border-top: 1px solid #cacaca !important;
   z-index: 999;

   .order-menu {
     width: 350px;
     margin: 0 auto !important;

     .menu-item {
       text-align: center;
       padding-top: 10px !important;
       line-height: 30px;
       background-color: #fff;
       padding-left: 11px !important;
       padding-right: 11px !important;

       .title {
         padding: 0;
       }

       .icon {
         .anticon {
           font-size: 30px;
         }
       }
     }
   }
}

.myrecipes-mobile-menu-container {
   .order-menu {
     width: 160px;
   }
}

.section-name-input {
  width: 80%;
  margin-left: 10% !important;
}

.ant-page-header {
  max-width: 1200px;
  margin: 0 auto !important;
}

.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -160px;
  margin-left: -200px;
  width: 400px;
  height: 265px;
  // border: 1px solid red;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  // background-opacity: 0.85;
  border: 1px solid #f67c4d;

  a {
    color: $main_color_1;

    &:hover {
        color: #000;
    }
  }

  button {
    border-radius: 15px !important;
    border: none !important;
    background-color: $main_color_1 !important;
    text-transform: uppercase;
    color: #fff !important;
    &:hover {
      background-color: #f67c4d !important;
    }
  }

  .info {
    color: #000;
  }

  &.reset-password-form  {
    height: 185px;

    form {
      margin-top: 30px;
    }
  }

  &.change-password-form {
    height: 230px;

    form {
      margin-top: 20px;
    }
  }
}

.get-password-form {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -160px;
  margin-left: -215px;
  width: 430px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.85);
}

.App {
  background-color: #fff;
}

.ant-layout-content {
  max-width: 2000px;
  // margin: 70px auto 0 auto;
  min-height: 60vh !important;
  background-color: #fff;

  // &-mobile {
  //   margin: 0 auto 20px auto;
  // }
}

.orders-actions {
  padding: 0 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.ant-picker-calendar-header {
  padding-left: 24px !important;
  padding-right: 24px !important;
}


// .ant-collapse-header {
  // text-align: center !important;
  // margin-left: 200px;
  // border: 1px solid red;
  // > div, > span {
  //   margin: 0 auto;
  // }
// }

.ant-menu-item-disabled {
  span {
    color: #cacaca;
  }
}

.mobile-add-btn {
  position: fixed !important;
  right: 20px;
  bottom: 60px;

  &.with-menu {
    bottom: 100px;
  }

  &.ant-btn {
    &.ant-btn-circle.ant-btn-lg {
      min-width: 60px;
    }

    &.ant-btn-lg {
      padding: 0;
      height: 60px;
    }
    span {
      font-size: 50px;
      margin-top: -10px;
    }
  }
  z-index: 999;
}

.menu-drawer {
  .ant-drawer-close {
    display: none;
  }

  .ant-menu {
    width: 348px;
  }
}

.ant-pagination {
  padding-right: 10px !important;
}

.layout-content-authenticated {
  padding-bottom: 80px !important;
}

.ant-alert {
  &.registration-alert {
    margin-bottom: 10px;
    border-radius: 4px;
  }

  &.ant-alert-info {
    border: 1px solid $color2;
    background-color: $color2_light;
  }

  &.calculator-alert {
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px;

    &.on-mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &.panel-alert {
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px;

    &.on-mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.alert-wrapper {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.calculator-help-list {
  margin-left: 20px;
  margin-top: 10px;
}

.calculator-results-left {
  text-align: right;
}

.calculator-results-right {
  text-align: left;
  display: flex !important;
  align-items: center;
  padding-left: 5px !important;

  > b {
    margin-right: 5px;
  }
}

.calculator-results-header-left {
  text-align: right;
}

.calculator-results-header-right {
  text-align: left;
  padding-left: 5px;
}

.calculator-results-collapse {
  margin-top: 10px !important;
  border: none !important;
  background-color: transparent !important;

  .ant-collapse-item, .ant-collapse-content {
    border: none !important;
  }
}

.ant-checkbox-wrapper.register-agreement-checkbox > span:not(.ant-checkbox)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form {
  &.register-form {
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #f67c4d;

    .ant-form-item-control-input-content {
      text-align: left;
    }

    a {
      color: $main_color_1;

      &:hover {
          color: #000;
      }
    }

    button {
      border-radius: 15px !important;
      border: none !important;
      background-color: $main_color_1 !important;
      text-transform: uppercase;
      color: #fff !important;
      &:hover {
        background-color: #f67c4d !important;
      }
    }
  }

  &.profile-form {
    max-width: 550px;
    margin: 0 auto;
    padding: 0 20px 20px 20px;

    .ant-select-selection-item {
      text-align: left;
    }
  }

  .help-icon-mobile {
    font-size: 18px !important;
  }
}

.banner1 {
  .banner1-register {
    // width: 800px !important;
  }
}

.mobile-list {
  .ant-list-item-meta-title, .ant-list-item-meta-description {
      text-align: left;
      padding-left: 10px;
  }

  .ant-list-pagination {
    text-align: left;
    padding-left: 10px;
  }
}

@media screen and (max-width: 575px) {
  .login-form {
    height: 320px;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .get-password-form {
    width: 100%;
    margin-left: 0;
    left: 0;
  }
  .ant-form {
    &.register-form {
      .ant-form-item-control-input-content {
        text-align: center;
      }
    }
  }

  .calculator {
    .calc-params {
      .ant-form-item-label {
        flex-basis: 58.3333% !important;
        // text-align: right !important;
        padding-right: 5px !important;

        &:after {
          content: ":";
        }
      }

      .ant-form-item-control {
        // flex-basis: 25% !important;
      }
    }
  }

  .calculator-results-header-left {
    text-align: center;
  }

  .calculator-results-header-right {
    text-align: center;
    padding-left: 0;
  }

  .login-form {
    height: 350px !important;
  }

  .reset-password-form  {
    height: 225px !important;
  }

  .change-password-form {
    height: 310px !important;
  }

  .confirm-password-change {
    margin-top: 60px !important;
  }
}

@media screen and (max-width: 510px) {
  .calculator {
    .calc-params {
      .ant-form-item-label {
        flex-basis: 100% !important;
        text-align: left !important;
        padding-right: 0 !important;

        &:after {
          content: "";
        }
      }
    }
  }
}

.ant-menu-submenu, .ant-menu-inline  {
  font-family: 'Roboto';

  .ant-menu-item, .ant-menu-submenu-title {
    background-color: transparent !important;
    color: #2c2c2c !important;

    a:hover,
    &-active {
      color: $main_color_1 !important;
    }

    &-selected {
      a {
        color: #2c2c2c !important;
      }
    }
  }
}

.header-main-container {
  position: relative;
}

.header0, .header-container {
  height: 160px;
  background-color: #f5f5f5;

  .logo {
    margin-top: 50px;
    // max-height: 190px;
    max-width: 150px;
    width: 100%;
  }

  &-mobile {
    height: 60px;
    // text-align: left;

    .logo-mobile img {
      height: 70px;
      margin-top: 10px;
    }
  }

  .main-menu {
    max-width: 1200px;
    margin: 0 auto;
  }

  .ant-menu-horizontal {
    border-bottom: none;
    background-color: transparent;
    // text-align: right;

    margin-top: 77px;
    line-height: 38px !important;

    .ant-menu-title-content, .ant-menu-title-content > a {
      color: #2c2c2c !important;
    }

    .ant-menu-item, .ant-menu-submenu {
      color: green !important;
      font-weight: 500;
      font-family: 'Roboto';

      &:hover,
      &-active,
      &-open,
      &-selected {
        color: #2c2c2c !important;

        &::after {
          border-bottom: 4px solid $main_color_1 !important;
          width: 50%;
          margin: 0 auto;
        }
      }
    }

    > .ant-menu-item, > .ant-menu-submenu {
      &::after {
        border-bottom: 4px solid transparent !important;
        width: 50%;
        margin: 0 auto;
      }
    }
  }

  .logo {
    float: left;
    margin-left: 18px;
  }
}

.hamburger {
  float: right;
  display: block;
  position: absolute;
  width: 20px;
  height: 14px;
  cursor: pointer;
  top: 20px;
  right: 16px;
  z-index: 1001 !important;

  em {
    z-index: 1001 !important;
    display: block;
    width: 100%;
    height: 2px;
    // background: #fff;
    background: #000;
    margin-top: 6px;
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  }

  :first-child {
    margin-top: 0;
  }

  &.open {
    em {
      &:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
        background: #000;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
        background: #000;
      }
    }
  }
}

:not(.ant-picker-cell-in-view) {
  .calendar-badge > sup {
    background-color: #cacaca;
    margin: 0 auto;
  }
}

.ant-picker-cell-in-view {
  .calendar-badge > sup {
    background-color: $color2;
    margin: 0 auto;
  }
}

.topper-video {
  margin-top: 20px;
}

.ant-picker-calendar {
  .calendar-badge-wrapper {
    text-align: center;
    margin-top: 20px;
  }
}

.ant-drawer-body {
  padding: 0 !important;

  .ant-menu-inline {
    border-right: none;

    .ant-menu-item {
      margin: 0 !important;
    }
  }
}

.pwa-prompt-ios-icon {
  height: 20px;
  margin-left: 10px;
}

.calculator {
  max-width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;

  .ant-form-item {
    margin-bottom: 8px !important;
  }

  .dynamic-delete-button {
    font-size: 18px;
    line-height: 38px;
  }
  .ant-select-open {
    .ant-select-selection-placeholder {
      color: transparent !important;
    }
  }

  input:focus::placeholder {
    color: transparent;
  }

  .ant-card {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;

    &.on-mobile {
      margin-bottom: 10px;
    }

    &:not(.on-mobile) {
      border-bottom: none !important;
    }
  }

  .ant-card-head-title {
    text-align: left;
    font-weight: bold;
  }
}

.menu-profile {
    float: right;

    .anticon-user {
      font-size: 20px !important;
    }
}

@media screen and (max-width: 1050px) {
  .menu-profile {
    float: none;
  }
}

.ant-select-selection-item
{
  text-align: left;
}

// calculator
.calculator-actions-menu {
  .ant-menu-item-active {
    color: inherit !important;
  }
}

.calculator-help-icon {
  &.anticon {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.calculator-results-divider-wrapper {
  width: 200px;
  margin: 0 auto;
}

.calculator-help-popup {
  padding: 20px;
  overflow-y: auto;
  max-height: 50vh;
}

.promo-help-popup {
  padding: 20px;
  overflow-y: auto;
  max-height: 50vh;

  h2 {
    margin-bottom: 15px;
  }
}

.calculator-help-drawer {
  padding: 20px;
}

.ant-popover {
  max-width: 500px;
}

.adm-dropdown-nav {
  border-bottom: none !important;

  .adm-dropdown-item-title-arrow {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .adm-dropdown-item-title {
    padding-right: 0 !important;
  }
}

.calculator-help-popup-close {
  float: right;
  &.ant-btn {
    padding-top: 0;
    padding-right: 0;
  }
  .anticon {
    font-size: 22px;
  }
}

.input-100p {
  width: 100% !important;
}

.calculator-mobile {
  .on-mobile {
    .ant-card-body {
      padding: 10px;
    }
  }

  .toolbox {
    row-gap: 0 !important;
  }

  .ant-card-bordered {
    border-left: none;
    border-right: none;
  }

  :not(:first-child) {
    .ant-card-bordered {
      border-top: none;

      .ant-card-head-title {
        padding-top: 2px;
      }
      .ant-card-extra {
        margin-top: -12px;
      }
    }
  }
}

.form-item-actions {
  text-align: left;
  padding-left: 10px !important;
}

.calc-params {
  .ant-row {
    > div:nth-child(2) {
      text-align: left;
    }
  }
}

.ant-image-mask-info {
  visibility: hidden;
}

// promo list and show
.promo-list {
  .ant-list-item-extra {
    order: 1;
  }

  .ant-list-item-main {
    order: 2;
    padding-bottom: 30px;
  }
}

.promo-list {
  margin-left: 20px;
}

.mobile-image-preview-close {
  position: fixed;
  top: 9px;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  right: 11px;
  font-size: 23px;
  color: #000;
  z-index: 1002;
}

.ant-image-img {
  padding: 5px;
}

.images-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.promo-video-iframe {
  width: 40vw;
  height: 22.5vw;
  max-width: 480px;
  max-height: 270px;
}

.promo-video-iframe-mobile {
  width: 80vw;
  height: 45vw;
}

.verified-promo {
  font-size: 16px;

  .anticon {
    color: rgb(82, 196, 26);
    font-size: 35px;
  }
}

.promo-header {
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
}

.promo-content {
  text-align: left;
}
// promo list and show end

// static content
.static-content {
   text-align: left;
   max-width: 1000px;
   margin: 30px auto;
   padding-left: 30px;
   padding-right: 30px;
   font-size: 16px;
   min-height: 60vh;

   .regulations-change {
     color: red;
   }

   ul > li > ul {
     margin-left: 20px;
   }
}

.colored {
  font-weight: bold;
}

// user profile
.profile-skeleton {
  max-width: 550px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.partner-description {
  ul {
    text-align: left;
  }
}

.training-level {
  max-height: 25px;
}

.training-list {
  margin: 0 auto !important;
  max-width: 1200px;

  .ant-card-meta-detail {
    width: 100%;
  }
}

.myrecipes-list, .list {
  margin: 0 auto !important;
  max-width: 1200px;
  padding: 0 20px;

  &-title, &-title:hover {
    color: $main_color_1;
  }

  &-item {
    border: 1px solid red;
    border-radius: 15px !important;

    .ant-card-cover {
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
    }
  }
}

.recipe-ingredients, .recipe-preparings {
  max-width: 550px;
  margin: 0 auto !important;
  text-align: left;

  .ant-list-items {
    .ant-list-item {
      border-bottom: none !important;
      position: relative;

      &::before {
        content: "•";
        position: absolute;
        top: 0;
        left: -10px;
      }
    }
  }
}

.trainer-list {
  margin-top: 20px;
  padding: 0 20px 20px 15px;
  text-align: left;
  color: #404040;
  min-height: 140px;

  &-visible {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &-level {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-duration {
    position: absolute;
    right: 2px;
    top: 10px;
  }

  &-description {
    margin-top: 14px;
    margin-bottom: 10px;
    padding: 5px;
  }

  &-text {
    font-family: 'Lato';
    font-weight: 400;
    color: #929292 !important;
    margin-top: 4px;
  }

  .button-wrapper {
    // height: 50px;
    // position: relative;
    a {
      font-family: Rubik;
      color: $main_color_1;
      font-size: 13px;
      font-weight: 500;

      &:hover {
        color: #000;
      }

      &::after {
        content: "→";
        font-size: 30px;
      }
    }
  }

  &-title {
    color: $main_color_1;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 500;

    &:hover {
      color: #000 !important;
    }
    // margin-top: 10px !important;
  }

  &-author-wrapper {
    margin-bottom: 5px;
    position: relative;
  }

  &-author {
    color: #a8a8a8;
  }

  // button {
  //   background-color: #b24669;
  //   border: none;
  //   box-shadow: 5px 5px #e8c7d2;
  //   border-radius: 0;
  //   color: #fff !important;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   font-family: 'Yeseva One';
  // }
  //
  // button:hover {
  //   top: 5px;
  //   left: 5px;
  //   box-shadow: none;
  // }
}

.trainer-card {
  margin: 0 auto !important;
  border-top-right-radius: 0;
  text-align: left;
  background-color: #fff !important;
  border-radius: 10px !important;

  .ant-card-body {
    padding: 0;
  }

  .ant-card-cover {
    border: none;

    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.training-list {
  margin-top: 20px;
  padding: 18px 20px 20px 15px;
  text-align: left;
  color: #404040;
  min-height: 200px;

  &-visible {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &-level {
    // position: absolute;
    // top: 0;
    // right: 0;

    font-weight: 700;
    font-family: Roboto;
    font-size: 13px;
    color: #000;

    .circle {
      background-color:#fff;
      border:1px solid #000;
      height:10px;
      border-radius:50%;
      width:10px;
      &-full {
        background-color: #000;
      }
    }
  }

  // .training-duration {
  //   position: absolute;
  //   right: 2px;
  //   top: 10px;
  // }

  &-description {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'Lato';
    font-weight: 400;
  }

  &-text {
    font-family: 'Lato';
    font-weight: 400;
    color: #929292 !important;
    margin-top: 4px;
  }

  &-title {
    color: $main_color_1;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 500;
    &:hover {
      color: #000 !important;
    }
  }

  .button-wrapper {
    // height: 50px;
    // position: relative;
    a {
      font-family: Rubik;
      color: $main_color_1;
      font-size: 13px;
      font-weight: 500;

      &:hover {
        color: #000;
      }

      &::after {
        content: "→";
        font-size: 30px;
      }
    }
  }

  .training-author {
    color: $main_color_1;
    &:hover {
      color: #000;
    }
  }

  .training-author-wrapper {
    margin-bottom: 5px;
    position: relative;
  }

  // .button-wrapper {
  //   position: relative;
  //   height: 50px;
  //   a {
  //     font-family: Roboto;
  //     color: $main_color_1;
  //     font-weight: 700;
  //     &:hover {
  //       color: #000;
  //     }
  //   }
  // }

  // button {
  //   background-color: #b24669;
  //   border: none;
  //   box-shadow: 5px 5px #e8c7d2;
  //   border-radius: 0;
  //   color: #fff !important;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   font-family: 'Yeseva One';
  // }
  //
  // button:hover {
  //   top: 5px;
  //   left: 5px;
  //   box-shadow: none;
  // }
}

.training-card {
  margin: 0 auto !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  text-align: left;

  .ant-card-body {
    padding: 0;
  }

  .ant-card-cover {
    border: none !important;
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.training-author {
  .ant-typography {
    color: #404040 !important;
  }

  h3 {
    text-align: left;
    color: #c62595 !important;
    font-family: 'Yeseva One';
    font-weight: 300 !important;
  }

  .training-author-link {
    text-align: left;
    a {
      font-size: 14px;
      color: #c62595;
    }

    .anticon {
      font-size: 20px;
    }
  }
}

.ant-page-header-heading-title {
  color: #000;
  font-family: Rubik;
  font-weight: 500 !important;
  text-align: left;
  // padding: 0 20px;
}

.training {
  &-live-board {
      // height: 300px;
      padding: 100px 0;
      border: 1px solid $main_color_3;
      border-radius: 15px;
      font-family: Rubik;
      font-weight: 500;
      font-size: 16px;
  }

  .ant-typography {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-teaser-image {
    width: 100%;
    max-width: 560px;
    border-radius: 15px;

    &-click {
      position: absolute;
      top: 0;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .ant-typography {
    color: #404040 !important;

    ul {
      list-style-type: disc !important;
    }

    h3 {
      color: #000 !important;
      font-family: Rubik;
      font-weight: 500 !important;
      font-size: 19.5px;
    }
  }

  &-certificate-status {
    font-weight: 700 !important;
  }

  .training-download {
    .anticon {
      font-size: 25px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .training-author-name {
    text-align: left;
    // padding-left: 10px;
    height: 80px;
    padding-top: 20px;

    a {
      color: #000;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .training-video {
    video {
      background-color: #c0c0c8;
    }
  }

  .training-author-link {
    text-align: left;

    a {
      font-size: 14px;
      color: #c62595;
    }

    .anticon {
      font-size: 20px;
    }
  }

  .training-about {
    // background-color: #f8f8f8;
    border: 1px solid #f0f0f0;
    text-align: left;
    padding: 10px;
    border-radius: 15px;
    margin-left: 26px;
    margin-right: 26px;

    h3 {
      color: #000 !important;
      font-family: Rubik;
      font-weight: 500 !important;
      font-size: 19.5px;
    }
  }

  button.download {
    background-color: #b24669;
    border: none;
    box-shadow: 5px 5px #B2D2E0;
    border-radius: 0;
    color: #fff !important;
    font-family: 'Yeseva One';

    &:disabled {
      background-color: #a0a0a0 !important;
      box-shadow: none;
    }

    .anticon {
      margin-left: 8px;
    }

    &:hover:enabled {
      top: 5px;
      left: 5px;
      box-shadow: none;
    }
  }
}

.training-opinion-rating {
  text-align: center;
  padding-bottom: 10px;
}

.confirm-password-change {
  color: #000;
  margin-top: 40px;
}

.ant-result-content {
  background-color: transparent !important;
}

.inspiration-form-icons {
  .anticon {
    font-size: 20px;
    margin-top: 8px;
  }
}

.inspiration-loading {
  opacity: 0.5;
}

.inspiration-preview {
  &.inspiration-generated {
    border: 4px solid #1890ff !important;
  }
}

.enlarged-image-preview {
  &.enlarged-image-generated {
    .ant-card-cover {
      margin: 0 !important;
    }

    border: 4px solid #1890ff !important;
  }
}

.inspiration-upload {
  .ant-form-item-control-input {
    min-height: 0;
  }
}

form.inspiration {
  &.inspiration-mobile {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.printout-item {
  .ant-card-cover {
    padding-top: 20px;
  }
}

.sub-not-good {
  opacity: 0.6;
}

#confetti {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 4s, opacity 2s 2s linear;
  }
}

.cta-box {
  margin: 24px 20px 0 20px;

  &-nomargin {
    margin: 24px 0 0 0;
  }

  &-inside {
    border: 1px solid #f67c4d;
    border-radius: 15px;
    margin: 20px auto;
    max-width: 500px;
    padding: 20px;
    text-align: center;

    .anticon {
      color: $main_color_1;
    }
  }

  &-button:not(.ant-btn-text) {
    border-radius: 15px !important;
    border: none !important;
    background-color: #f67c4d !important;
    text-transform: uppercase;
    color: #fff !important;
    &:hover {
      background-color: $main_color_1 !important;
    }
  }
}

.slick-image {
  margin: 0 auto;
}

.slick-prev, .slick-next {
  &:before {
    font-size: 40px !important;
    color: #dfdfdf !important;
  }
}

.slick-slider {
  width: 90% !important;
  margin: 0 auto;
}

.training-filter {
  text-align: left;

  .ant-btn-primary, .ant-btn-primary:active {
    background-color: $main_color_1 !important;
    border: none;

    &:hover {
      background-color: #f67c4d !important;
    }
  }
}

.ant-anchor-wrapper {
  background-color: #fff !important;
  border-bottom: 1px solid #bfbfbf;
  padding-top: 20px;

  .ant-anchor-ink {
    display: none;
  }

  .ant-anchor-link {
    float: left;
  }
}

.cylinder {
  /* this variable will define how much ci*/
  // --r: 40px;
  /* whatever values/units you want */
  // width: 250px;
  // height: 200px;
  position: absolute;
  background:
    radial-gradient(50% var(--r) at 50% var(--r), #0003 99.99%, #0000 0),
    // radial-gradient(50% var(--r) at 50% calc(100% - var(--r)), #fff3 99.99%, #0000 0),
    #369;
  border-radius: 100% / calc(var(--r) * 2);
  // transition: all 0.5s ease-in-out;

  &.cylinder-selected {
    background:
    radial-gradient(50% var(--r) at 50% var(--r), #0003 99.99%, #0000 0),
    // radial-gradient(50% var(--r) at 50% calc(100% - var(--r)), #fff3 99.99%, #0000 0),
    #acacac;

  }
  &:hover:not(&.cylinder-selected) {
    background:
    radial-gradient(50% var(--r) at 50% var(--r), #0003 99.99%, #0000 0),
    // radial-gradient(50% var(--r) at 50% calc(100% - var(--r)), #fff3 99.99%, #0000 0),
    #acacac;
    cursor: pointer;

  }
}

.rectangle {
  .rect-cake-wrapper {
    &.rect-cake-selected > .rect-cake > div {
      background-color: #acacac;
      cursor: default;

      &.side1 {
        background-color: #8a8a8a !important;
      }
    }
  }

  .rect-cake {
      // width: 112px;
      // height: 112px;
      // top: 150px;
      transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      transform: rotateX(-12deg) rotateY(-25deg) rotateZ(0deg); // good
      // transform: rotateX(-52deg) rotateY(10deg) rotateZ(0deg);
      // -moz-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
      // -webkit-transform: rotateX(-12deg) rotateY(-38deg) rotateZ(0deg);
      margin: auto;
      position: absolute;
      // left: 300px;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      margin: 0;
  }

  .rect-cake > div {
      position: absolute;
      // -webkit-transition: all 0.5s ease-in-out;
      // transition: all 0.5s ease-in-out;
      // width: 112px;
      height: 42px;
      // height: 122px;
      // border-top: 1px solid red;
      margin: 0;
      // float: left;
      overflow: hidden;
      // opacity: 0.85;
      // border: 1px solid #369;
  }

  .rect-cake {
    &:hover > div {
      background-color: #acacac;
      cursor: pointer;

      &.side1 {
        background-color: #8a8a8a !important;
      }
    }

  }

  .side1 {
      // height: 112px !important;
      transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(80px);
      // -moz-transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(80px);
      // -webkit-transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(80px);
      background-color: #29527b;
      // border: 1px solid #29527b;
  }
  .side2 {
      transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
      // -moz-transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
      // -webkit-transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
      background-color: #369;
  }
  .side3 {
      transform: translateX(0px) translateY(0px) translateZ(80px);
      // -moz-transform: translateX(0px) translateY(0px) translateZ(80px);
      // -webkit-transform: translateX(0px) translateY(0px) translateZ(80px);
      background-color: #369;
  }
  .side4 {
      transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(80px);
      // -moz-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(80px);
      // -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(80px);
      background-color: #369;
      // border: 1px solid red;
  }
  .side5 {
      transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
      // -moz-transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
      // -webkit-transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
      background-color: #369;
  }
  .side6 {
      transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
      // -moz-transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
      // -webkit-transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
      background-color: #369;
  }
}

.partners {
  background-image: url(../images/partners_bg.png);
  background-repeat: no-repeat;
  background-color: $main_color_2;
  min-height: 380px !important;

  &-item {
    width: 200px;
    background-color: #fff;
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .partners {
    padding: 24px 24px 0 6px !important;
  }
}

.stats {
  background-color: #7a2151;
  background-image: url(../images/box-top.png), url(../images/box-bottom.png);
  background-position: center top, center bottom;
  background-repeat: no-repeat;
  font-family: Rubik;
  font-weight: 700;
  color: #fff;
  min-height: 265px !important;
  // padding: 10px 0 0 0 !important;

  .stats-item-data {
    font-size: 50px;
  }
  .stats-item-desc {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .stats {
    .content0-block-wrapper {
      padding-top: 0 !important;
      padding-bottom: 50px !important;
    }
  }
}

.tools {
  .item {
    a {
      font-family: Rubik;
      font-weight: 400;
      font-size: 16px;
      color: #000;
      text-transform: uppercase;
    }

    &:hover {
      color: #000;

      .line {
        border-bottom: 3px solid #d06c99;
        display: block;
        position: absolute;
        width: 100px;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
    }
  }
}

.welcome {
  height: auto !important;
  max-width: 1400px;
  margin: 0 auto;

  .content2-title {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    color: $main_color_1 !important;
  }

  .content2-img {
    align-items: left !important;
    justify-content: left !important;
  }

  .content2-content {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    p {
      margin-bottom: 30px;
    }
  }

  .ant-space {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .welcome {
    .content2-img {
      min-height: 500px !important;
    }
  }
}

@media screen and (max-width: 575px) {
  .welcome {
    .content2-img {
      min-height: 350px !important;
    }
  }
}

.explain {
  min-height: 200px !important;
  padding-left: 20px;
  padding-right: 20px;
  .content13-wrapper {
    min-height: 200px;
  }
}

button.sc-button {
  border-radius: 32px;
  text-transform: uppercase;
  color: #fff !important;
  border: none;
  font-family: 'Roboto';
  font-weight: 400;

  background-color: $main_color_1 !important;

  &:hover {
    background-color: $main_color_2 !important;
  }

  &-reverse {
    background-color: $main_color_2 !important;

    &:hover {
      background-color: $main_color_1 !important;
    }
  }

  &:disabled {
    background-color: #d9d9d9 !important;
  }
}

.content {
  &-more {
    a {
      color: $main_color_1;
      font-family: Roboto;
      font-weight: 400;

      &:hover {
        color: #000;
      }

      &::after {
        content: "→";
        font-size: 30px;
      }
    }
  }
}

.training-search {
  position: absolute;
  top: 50px;
  right: 0;

  &-input {
    border-radius: 20px !important;
  }

  &-button {
    width: 30px;
    height: 30px;
    padding: 2px 0 0 2px !important;
    text-align: center !important;
    font-size: 20px !important;
    border-radius: 15px !important;
    border: none !important;
    background-color: $main_color_1 !important;
  }
}

@media screen and (max-width: 767px) {
  .training-search {
    display: none !important;
  }
}

.get-it-on {
  border-radius: 10px;
}

.panel {
  .content0-block-item {
    background-color: #fff;
    border-radius: 15px;
    padding: 25px 0 15px 0;
    font-family: Rubik;
    border: 2px solid #fff;

    &:hover {
      border: 2px solid #ffe082;
      cursor: pointer;
      a {
        color: $main_color_1;
      }
    }

  }

  a {
    color: #000;
    font-size: 17px;
    font-weight: 500;

    &:hover {
      color: $main_color_1;
    }
  }
}

.global-message {
  color: var(--text-color);
  background-color: var(--bg-color);
  font-family: Rubik;
  font-weight: 500;
  font-size: 15px;

  p {
    margin: 0;
    padding: 10px;
  }

  a {
    color: var(--link-color);

    &:hover {
      color: var(--link-color);
      text-decoration: underline;
    }
  }
}

.teaser-link {
  font-family: Rubik;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
}

.rce-container-mbox {
  button {
    float: right;
  }
}

.rce-mbox-forward {
  width: 100px !important;
  box-shadow: none !important;

  &.rce-mbox-forward-left {
    left: -120px !important;
  }
  &.rce-mbox-forward-right {
    right: -120px !important;
  }
}

.rce-mbox-forward::after {
  content: ' odpowiedz' !important;
}

.rce-mbox-forward:hover::after {
  text-decoration: underline;
}

.rce-mbox {
  background-color: #f5f5f5 !important;
  .rce-mbox-left-notch {
    fill: #f5f5f5;
  }
  &.rce-mbox-right {
    background-color: $main_color_2 !important;
    .rce-mbox-right-notch {
      fill: $main_color_2;
    }
  }
  .rce-mbox-text::after {
    content: '' !important;
  }
}

.training-form {
  text-align: center;

  .ant-btn-primary, .ant-btn-primary:active {
    background-color: $main_color_1 !important;
    border: none;

    &:hover {
      background-color: #f67c4d !important;
    }
  }
}

.player-help-link {
   font-size: 13px !important;
   padding: 3px !important;
   span {
     text-decoration: underline !important;
   }
}
