$content0: content0;

.#{$content0}-wrapper {
  min-height: 446px;
  overflow: hidden;

  .#{$content0} {
    height: 100%;
    padding: 48px 24px 0 24px;
    max-width: 1200px;

    >.title-wrapper {
      margin: 0 auto 0 20px;
      text-align: left;
      font-family: Rubik !important;
    }

    &-block {
      padding: 0 1%;
      display: inline-block;
      text-align: center;
      min-height: 200px;
      margin-bottom: 24px;
      img {
        width: 100%;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        width: 100px;
        height: 100px;
        margin: auto;
      }

      &-title {
        line-height: 32px;
        margin: 10px auto;
        font-size: 24px;

        // a {
          // font-weight: 300 !important;
        // }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .#{$content0} {
    padding: 12px 24px 0 24px !important;
  }
}
