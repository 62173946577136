@import "../variables";

.footer1-wrapper {
  // background-image: url('../../images/footer.png');
  background-color: #7a2151;
  // background-size: cover;
  // background-position: right;
  // background-size: 48px 48px;

  // .drip0 {
  //   background-color: #fff;
  //   width: 560px;
  //   height: 180px;
  //   position: absolute;
  //   border: 1px solid red;
  //   right: 0;
  //   top: 0;
  // }
  // .drip {

    background-image: url('../../images/box-top.png');

    &.on-index {
      background-image: url('../../images/footer-top.png');
      // background-image: url('../../images/footer-index.svg');
    }

    &:not(.on-index) {
      margin-top: 80px;
    }
  //   background-repeat: no-repeat;
  //   // background-size: 600px 201px;
  //   background-size: 100% 100%;
  //


    // background-position: right 0px top -2px;


    // width: 600px;
    // height: 200px;
  //   position: absolute;
  //   // border: 1px solid red;
  //   right: 0;
  //   top: 0;
  //   // background-color: #fff;
  //   // z-index: 9999;
  // }

  // background-image: url('../../images/footer.svg');
  background-repeat: no-repeat;
  // background-size: 50%;

//     rect {
//     fill: red; /* changes the color to red */
// }
// }
  // background-position: top;
  // background: url("../../images/footer2.svg") no-repeat center top #fff;
  // background-size: contain;
  // border: 1px solid red;

  // background-attachment: fixed;




  // .drip {
  //
  //   // background-size: 600px 201px;
  //
  //   // background-position: right 10px top 10px;
  //   width: 600px;
  //   height: 200px;
  //   position: absolute;
  //   // border: 1px solid red;
  //   right: 0;
  //   top: 0;
  //   // background-color: #fff;
  //   // z-index: 9999;
  // }


  // background: #7a2151, url('../../images/footer.svg') ;

  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #fff;
  font-weight: 400;

  h2 {
    color: #fff !important;
  }

  .footer1 {
    .home-page {
      padding: 64px 24px 80px;
    }
  }
  .block {
    padding: 0 32px;
    text-align: left;
    .logo {
      max-width: 180px;
    }
    .slogan {
      font-size: 12px;
      margin-top: -20px;
    }
    .content0 {
      text-align: left;
      padding-left: 40px;
    }
    .link {
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    >h2 {
      margin-bottom: 24px;
      color: #fff;
    }
    a {
      color: #fff;
      margin-bottom: 12px;
      // float: left;
      // clear: both;
      &:hover {
        color: $main_color_2;
      }
    }
  }
  .copyright-wrapper {
    width: 100%;
    border-top: 1px solid fade($line-color, 10);
    .home-page {
      padding: 0 24px;
      overflow: hidden;
    }
    .copyright {
      height: 80px;
      text-align: center;
      line-height: 80px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
    &-wrapper {
      .footer1 {
        .home-page {
          padding: 64px 24px 32px;
        }
      }
    }
    .logo {
      margin: 0 auto 24px;
    }
    .block {
      text-align: center;
      margin-bottom: 32px;
      padding: 0;

      .content0 {
        text-align: center;
        padding-left: 0;
      }

      .link {
        text-align: center;
      }
    }
    >ul {
      width: 90%;
      margin: 20px auto 0;
      padding: 10px 0;
      >li {
        width: 100%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .copyright {
      &-wrapper {
        .home-page {
          padding: 0;
          .copyright {
            font-size: 12px;
          }
        }
      }

      span {
        // width: 90%;
      }
    }
  }
}
